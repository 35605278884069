import { NextPage } from 'next';
import { Layout } from '@/components/Layout';
import { ContentWrapper } from '@/components/SS/ContentWrapper';
import Image from 'next/image';

// import Schedule from '@/components/SS/Schedule';
// import Link from 'next/link';
// import FAQs from '@/components/SS/FAQs';

const Page: NextPage = (props) => {
  const site = {
    title: `Spicoli Scramble - Open Invitational Golf Tournament`,
    header: 'TBA',
    description: 'Open Invitational Golf Tournament - Sunday, May 5th - Los Angeles, CA',
    image: '/static/images/banner.png',
  };

  return (
    <Layout customSite data={site} noPadding>
      <ContentWrapper>
        <div className="">
          <img
            className="pt-16 lg:h-80 max-w-[200px] lg:max-w-full mx-auto mb-10 overflow-visible block"
            id="img-1"
            src="/static/images/ss-logo.svg"
          />
        </div>

        <div className="text-sm pb-16 px-5 relative pt-0">
          {/* <div className="w-full max-w-[71.25rem] overflow-hidden m-auto leading-none  capitalize">
            <div className="items-center flex-col justify-center text-center flex m-auto">
              <h2 className="pt-3 my-0 m-auto mt-5 mx-auto mb-3 font-display z-10 text-[28px] lg:text-[3.50rem] font-medium">
                Open Invitational Golf Tournament
              </h2>
            </div>
          </div> */}
          <section className="overflow-hidden" id="section-1">
            <div className="relative h-auto max-w-[58.88rem] m-auto" id="div-2">
              <img
                className=" 
                align-middle inline-block bottom-auto w-[58.88rem] h-auto max-w-full"
                id="img-1"
                src="/static/images/swing-graphic.svg"
              />
            </div>
          </section>
          {/* <div>
            <h2 className="text-[28px] lg:text-[3.50rem] leading-[-20px] lg:leading-none lg:pt-3 text-center font-display font-medium -mt-36 lg:mt-0">
              Sunday, May 5th<span className="spacing-break">           </span>The Lakes at El Segundo
            </h2>
          </div> */}
        </div>

        <div>
          <h2 className="text-xl lg:text-[2.38rem] leading-none pt-3 text-center uppercase max-w-[50.00rem] mx-auto font-headline px-5 mb-12">
            Thank you for joining us for the Spicoli Scramble Open Invitational Golf Tournament
          </h2>
        </div>

        <div className="text-sm p-5 pb-16 lg:py-8 lg:px-20 lg:pb-32" id="merch">
          <p className="text-[1.75rem] leading-8 font-medium text-ssOrange text-center uppercase font-headline">
            Merch
          </p>
          <div className="my-10 max-w-md mx-auto">
            <a
              href="https://womrsvp.co/products/spicoli-scramble-open-invitational-commemorative-t-shirt"
              className="flex items-center -space-x-12">
              <Image
                src="/static/images/front.jpg"
                width="500"
                height="500"
                alt="Spicoli Scramble T-Shirt Front"
                className="rounded-2xl relative z-10 left-[7.5%] lg:-left-[50%] w-[50%] lg:w-full max-w-full"
              />
              <Image
                src="/static/images/back.jpg"
                width="500"
                height="500"
                alt="Spicoli Scramble T-Shirt Back"
                className="rounded-2xl relative top-12 left-[7.5%] lg:-left-[50%] w-[50%] lg:w-full max-w-full"
              />
            </a>
            {/* <p>
              The Spicoli Scramble Open Invitational, hosted at Top Golf's The Lakes @ El Segundo, is a golf tournament
              blending competitive play with a spirit of camaraderie and community. We created the event to honor the
              late Quinn Coleman's legacy through celebrating his love of the game. Proceeds will be donated to{' '}
              <a href="https://grammymuseum.org/support/quinn-coleman/" className="underline" target="_blank">
                Quinn Coleman Memorial Scholarship Fund
              </a>
              .
            </p> */}
            {/* <p className="mt-4">
              The tournament adopts a{' '}
              <Link href="#faq" className="underline">
                4-person scramble format
              </Link>
              , with 10 teams competing for the grand prize, battling it out on a beautiful 10-hole, 1,060 yard course
              that's approachable for beginners and fun for experienced golfers alike.
            </p> */}
          </div>
          <div className="flex items-center justify-center">
            <a
              href="https://womrsvp.co/products/spicoli-scramble-open-invitational-commemorative-t-shirt"
              className="w-full lg:w-auto text-green items-center cursor-pointer text-lg font-medium py-3 px-10 text-center uppercase rounded-2xl bg-ssLightGreen font-headline mt-8 noselect">
              Buy merch
            </a>
          </div>
        </div>

        {/* <div className="text-sm p-5 py-20 lg:px-20" id="participate">
          <p className="text-ssOrange text-[1.75rem] leading-8 font-medium text-center uppercase mb-5 font-headline">
            Participate
          </p>
          <p className="text-center mb-10">Get involved, whether on the course or around the clubhouse!</p>
          <div className="w-full lg:max-w-lg m-auto">
            <div className="flex-wrap justify-center flex gap-10">
              <div className="items-center flex w-full max-w-[21.88rem] rounded-xl gap-6" id="div-2">
                <div className="bg-green items-center justify-center flex w-20 h-20 rounded-2xl p-4">
                  <img className="align-middle inline-block w-12 h-12 max-w-full" src="/static/images/player.svg" />
                </div>
                <div className="flex flex-col gap-0">
                  <p className="mb-1 text-xl font-bold font-display flex items-center gap-4">Player</p>
                  <p>4-person scramble | 10 teams</p>
                  <span className="mt-2 border-2 border-ssOrange rounded-full px-2 uppercase text-ssOrange fw7-ns fw5 flex text-sm font-mono w-[176px]">
                    Waitlist available
                  </span>
                </div>
              </div>
              <div className="items-center flex w-full max-w-[21.88rem] rounded-xl gap-6" id="div-3">
                <div className="bg-green items-center justify-center flex w-20 h-20 rounded-2xl p-4">
                  <img
                    className="align-middle inline-block w-12 h-9 max-w-full"
                    src="/static/images/spectator-v2.svg"
                  />
                </div>
                <div className="">
                  <p className="mb-1 text-xl font-bold font-display">Spectator</p>
                  <p>Limited to 100 spots</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-center">
              <Link
                href="/participate"
                className="w-full lg:w-auto text-green items-center cursor-pointer text-lg font-medium py-3 px-10 text-center uppercase rounded-2xl bg-ssLightGreen font-headline mt-8 noselect">
                Purchase tickets
              </Link>
            </div>
          </div>
        </div> */}

        {/* <div className="text-sm p-5 lg:py-8 lg:px-20" id="tournament">
          <p className="text-[1.75rem] leading-8 font-medium text-ssOrange text-center uppercase font-headline">
            Tournament
          </p>
          <div className="text-center my-10 max-w-lg mx-auto">
            <p>
              The Spicoli Scramble Open Invitational, hosted at Top Golf's The Lakes @ El Segundo, is a golf tournament
              blending competitive play with a spirit of camaraderie and community. We created the event to honor the
              late Quinn Coleman's legacy through celebrating his love of the game. Proceeds will be donated to{' '}
              <a href="https://grammymuseum.org/support/quinn-coleman/" className="underline" target="_blank">
                Quinn Coleman Memorial Scholarship Fund
              </a>
              .
            </p>
            <p className="mt-4">
              The tournament adopts a{' '}
              <Link href="#faq" className="underline">
                4-person scramble format
              </Link>
              , with 10 teams competing for the grand prize, battling it out on a beautiful 10-hole, 1,060 yard course
              that's approachable for beginners and fun for experienced golfers alike.
            </p>
          </div>
        </div> */}

        {/* <div>
          <section className="">
            <img
              className="w-[35.00rem] w-full max-w-[35.00rem] mx-auto mb-10 px-5"
              id="img-1"
              src="/static/images/map.svg"
            />
          </section>
        </div> */}
        {/* 
        <div className="p-5 lg:py-8 lg:px-20" id="schedule">
          <p
            className="text-[1.75rem] leading-8 font-medium text-ssOrange text-center uppercase font-headline"
            id="p-1">
            Schedule
          </p>
          <Schedule />
        </div> */}

        {/* <div id="faq">
          <p className="text-[1.75rem] leading-8 font-medium text-ssOrange text-center uppercase font-headline">FAQ</p>
          <FAQs />
        </div> */}

        {/* <div id="partners">
          <section className="text-sm pt-16 pb-32">
            <p className=" text-lg leading-8 font-medium text-center uppercase mb-5 mt-0 font-headline" id="p-1">
              Partners
            </p>
            <div className="items-center justify-center flex flex-wrap gap-10 px-5" id="div-1">
              <img className="align-middle inline-block w-24 max-w-full" src="/static/images/swang.svg" />
              <img className="align-middle inline-block w-24 max-w-full" src="/static/images/grammy-museum-15-yr.png" />
              <img className="align-middle inline-block h-12 max-w-full" src="/static/images/lululemon-yogo.svg" />
              <img className="align-middle inline-block h-10 max-w-full" src="/static/images/only-golfers.svg" />
              <img className="align-middle inline-block h-16 max-w-full" src="/static/images/celaya-tequila.png" />
              <img
                className="align-middle inline-block h-12 max-w-full"
                src="/static/images/happy-dad-hard-seltzer.svg"
              />
              <img className="align-middle inline-block w-28 max-w-full" src="/static/images/topo-chico.svg" />
              <img className="align-middle inline-block w-12 max-w-full" src="/static/images/tba-logo-black.svg" />
              <img className="align-middle inline-block w-24 max-w-full" src="/static/images/HYPEBEAST-Logo.svg" />
              <img
                className="align-middle inline-block w-24 max-w-full"
                src="/static/images/billboard-vector-logo.svg"
              />
            </div>
            <p className=" text-lg leading-8 font-medium pt-16 text-center uppercase mb-5 mt-0 font-headline" id="p-2">
              Social
            </p>
            <div className="items-center justify-center flex gap-5 text-blue-700" id="div-2">
              <a className="underline inline-block max-w-full" href="https://www.instagram.com/spicoliscramble/">
                <img
                  className="cursor-pointer align-middle inline-block w-8 h-8 max-w-full"
                  src="/static/images/icons8-instagram-50.png"
                />
              </a>
              <a className="underline inline-block max-w-full" href="https://twitter.com/spicoliscramble">
                <img
                  className="cursor-pointer align-middle inline-block w-6 h-6 max-w-full"
                  id="img-3"
                  src="/static/images/X_logo_2023_original.svg"
                />
              </a>
            </div>
          </section>
        </div> */}
      </ContentWrapper>
    </Layout>
  );
};

export default Page;

import Link from 'next/link';
import Image from 'next/image';
import { FC, ReactNode, useEffect, useState } from 'react';

type ContentWrapperProps = {
  children: ReactNode;
};

export const ContentWrapper: FC<ContentWrapperProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Fuck it, we ball
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isOpen && isMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen, isMobile]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="content">
      <div className="bg-[#ffeac5] overflow-x-hidden text-green font-body min-h-screen relative w-full">
        <div
          className="bg-repeat-x box-border bg-[#ffeac5] h-12 lg:h-20 w-full z-30 fixed lg:relative top-0"
          style={{ backgroundImage: 'url("/static/images/checkered-pattern.svg")' }}
        />
        <nav className="flex flex-col lg:flex-row items-center justify-between p-5 lg:px-8 text-sm lg:text-lg">
          <Link href="/" className="my-6 hidden lg:block">
            <Image src="/static/images/ss-logo.svg" width="80" height="80" alt="Spicoli Scramble" />
          </Link>
          <button
            className="lg:hidden fixed right-2 top-2 z-40 bg-[#ffeac5] px-2 py-1 rounded-md border-2 border-[#817ebc] h-8 w-8"
            onClick={toggleMenu}>
            <i className={`fa ${isOpen ? 'fa-close' : 'fa-bars'} text-[#817ebc]`} />
          </button>
          <div
            className={`${
              isOpen && isMobile ? 'flex items-center justify-center py-32' : 'hidden'
            } w-full h-screen lg:h-auto lg:block lg:w-auto bg-[#ffeac5] lg:bg-transparent fixed overflow-hidden lg:overflow-visible top-0 left-0 right-0 bottom-0 lg:py-0 lg:static z-20 transition-all`}>
            <ul className="flex font-headline uppercase gap-12 lg:gap-4 items-center justify-center flex-col lg:flex-row text-2xl lg:text-lg">
              {/* <li>
                <Link href="/#tournament" className="hover:text-ssLightGreen transition-colors" onClick={toggleMenu}>
                  Tournament
                </Link>
              </li>
              <li>
                <Link href="/#schedule" className="hover:text-ssLightGreen transition-colors" onClick={toggleMenu}>
                  Schedule
                </Link>
              </li>
              <li>
                <Link href="/#faq" className="hover:text-ssLightGreen transition-colors" onClick={toggleMenu}>
                  FAQ
                </Link>
              </li> */}
              {/* <li>
                <Link href="/#partners" className="hover:text-ssLightGreen transition-colors" onClick={toggleMenu}>
                  Partners
                </Link>
              </li> */}
              <li>
                <Link
                  href="https://womrsvp.co/products/spicoli-scramble-open-invitational-commemorative-t-shirt"
                  className="hover:text-ssLightGreen transition-colors"
                  onClick={toggleMenu}>
                  Merch
                </Link>
              </li>
              <li>
                <a
                  href="https://cart.grammymuseum.org/donate/i/quinn-coleman"
                  target="_blank"
                  className="bg-green py-2 px-4 text-ssLightGreen hover:text-ssLightGreen/70 transition-colors rounded-xl">
                  Donate
                </a>
              </li>
              {/* <li>
                <Link
                  href="/participate"
                  className="bg-green py-2 px-4 text-ssLightGreen hover:text-ssLightGreen/70 transition-colors rounded-xl"
                  onClick={toggleMenu}>
                  Participate
                </Link>
              </li> */}
            </ul>
          </div>
        </nav>
        {children}
        <div
          className="bg-repeat-x box-border h-12 lg:h-20 w-full bottom-0 absolute"
          style={{ backgroundImage: 'url("/static/images/checkered-pattern.svg")' }}
        />
      </div>
    </div>
  );
};
